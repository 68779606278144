import { ApplicationRecord } from './application_record'
import { attr, belongsTo } from 'spraypaint';

export const AsistenciaAlumno = ApplicationRecord.extend({
  static: {
    jsonapiType: 'asistencias_alumno',

    ordenadas() {
      return this
        .order('ordenadas')
    }
  },
  attrs: {
    asignaturaIds: attr(),
    justificacionInasistenciaId: attr(),
    llegadaTarde: attr(),
    notificable: attr(),
    enOtraBurbuja: attr(),
    observaciones: attr(),
    otraJustificacion: attr(),
    presentismo: attr(),

    apellido: attr({ persist: false }),
    avatarUrl: attr({ persist: false }),
    documentoCompleto: attr({ persist: false }),
    domicilio: attr({ persist: false }),
    email: attr({ persist: false }),
    genero: attr({ persist: false }),
    id: attr({ type: Number, persist: false }),
    nombre: attr({ persist: false }),
    nombreCompleto: attr({ persist: false }),
    numeroLegajo: attr({ persist: false }),
    telefonoContacto: attr({ persist: false }),
    telefonoPersonal: attr({ persist: false }),
    tipoCursada: attr({ persist: false }),
    asignaturasCursadas: attr({ persist: false }),

    asistencia: belongsTo(),
  },
  methods: {
    resetPresentismo(presentismo) {
      // si desea marcar como ausente a un alumno que tiene media ausencia,
      // no modificar
      if (presentismo !== 'ausente' || this.presentismo === 'presente') {
        this.presentismo = presentismo
        this.llegadaTarde = null
      }

      if (presentismo === 'presente' || presentismo === null) {
        this.justificacionInasistenciaId = null
        this.otraJustificacion = null
        this.asignaturaIds = []
        this.notificable = false
        this.observaciones = null
      }
    }
  },
})
