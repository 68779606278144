<template lang='pug'>
  .alumno-cell(
    :class='{ "has-error": alumno.anyErrors() }'
    :data-key='`alumno#${alumno.id}`'
  )
    v-divider(v-if='index !== 0')
    .alumno--header
      .alumno--avatar
      .alumno--info
        .alumno--overline
          v-chip(label small) {{alumno.documentoCompleto}}
          v-chip(v-if='alumno.numeroLegajo' label small) {{alumno.numeroLegajo}}
          v-chip.v-chip-danger(v-if='alumno.tipoCursada == "recursada"' label small) {{alumno.tipoCursada}}
          v-chip.v-chip-warning(v-if='alumno.tipoCursada == "postergada"' label small) {{alumno.tipoCursada}}
        .alumno--nombre
          span.alumno--nombre--apellido.
            {{alumno.apellido}},&nbsp;
          span.alumno--nombre--nombre.
            {{alumno.nombre}}
        .alumno--mas-info
          v-btn(
            :color='alumno.anyErrors() ? "warning" : "secondary"'
            @click='toggle()'
            fab medium text
          )
            v-icon(v-if='!alumno.anyErrors()') {{expanded ? icons.collapse : icons.info}}
            v-icon(v-if='alumno.anyErrors()') {{icons.alert}}
      .alumno--detalles(v-if='expanded')
        .alumno--detalle.alumno--error(v-if='alumno.anyErrors()')
          .alumno--detalle--icon
            v-icon(size=30) {{icons.alert}}
          .alumno--detalle--valor
            .
              {{errorSummary()}}
        .alumno--detalle(v-if='alumno.asignaturasCursadas')
          .alumno--detalle--icon
            v-icon(size=30) {{icons.alert}}
          .alumno--detalle--valor
            p(
              :href='"cursa:" + alumno.asignaturasCursadas'
              target='_blank'
            ) {{alumno.asignaturasCursadas}}
        .alumno--detalle(v-if='alumno.telefonoContacto')
          .alumno--detalle--icon
            v-icon(size=30) {{icons.phone_classic}}
          .alumno--detalle--valor
            a(
              :href='"tel:" + alumno.telefonoContacto'
              target='_blank'
            ) {{alumno.telefonoContacto}}
        .alumno--detalle(v-if='alumno.email')
          .alumno--detalle--icon
            v-icon(size=30) {{icons.email_outline}}
          .alumno--detalle--valor
            a(
              :href='"mailto:" + alumno.email'
              target='_blank'
            ) {{alumno.email}}
        .alumno--detalle(v-if='alumno.domicilio')
          .alumno--detalle--icon
            v-icon(size=30) {{icons.earth}}
          .alumno--detalle--valor
            a(
              :href='"https://www.google.com/maps/search/?api=1&query=" + alumno.domicilio'
              target='_blank'
            ) {{alumno.domicilio}}
    .alumno--campos
      v-select(
        v-if='muestraPresentismo'
        v-model='alumno.presentismo'
        :appendIcon='icons.expand'
        :error='alumno.hasErrorFor("presentismo")'
        :errorMessages='alumno.errorsFor("presentismo")'
        :items='presentismosPosibles'
        :label='i18n.alumnos.fields.presentismo.caption'
        :prepend-icon='icons.absence'
        @change='changedPresentismo'
        outlined
      )
      v-switch(
        v-if='muestraLlamadasTarde'
        v-model='alumno.llegadaTarde'
        :error='alumno.hasErrorFor("llegadaTarde")'
        :errorMessages='alumno.errorsFor("llegadaTarde")'
        :label='i18n.alumnos.fields.llegadaTarde.caption'
        :prepend-icon='icons.blank'
        @change='changed()'
        outlined
      )
      v-select(
        v-if='noEsPresente'
        v-model='alumno.justificacionInasistenciaId'
        :appendIcon='icons.expand'
        :error='alumno.hasErrorFor("justificacionInasistenciaId")'
        :errorMessages='alumno.errorsFor("justificacionInasistenciaId")'
        :items='justificacionesPosibles'
        :label='i18n.alumnos.fields.justificacion.caption'
        :prepend-icon='icons.clear'
        @change='toggleNotificableSegunJustificacion'
        outlined
      )
      v-text-field(
        v-if='requiereOtraJustificacion'
        v-model='alumno.otraJustificacion'
        :error='alumno.hasErrorFor("otraJustificacion")'
        :errorMessages='alumno.errorsFor("otraJustificacion")'
        :label='i18n.alumnos.fields.otra_justificacion.caption'
        :prepend-icon='icons.blank'
        @change='changed()'
        outlined
      )
      v-switch(
        v-if='false && noEsPresente'
        v-model='alumno.notificable'
        :error='alumno.hasErrorFor("notificable")'
        :errorMessages='alumno.errorsFor("notificable")'
        :label='i18n.alumnos.fields.notificable.caption'
        :prepend-icon='icons.blank'
        @change='changed()'
        outlined
      )
      v-select(
        v-if='requiereAsignaturas'
        v-model='alumno.asignaturaIds'
        :appendIcon='icons.expand'
        :error='alumno.hasErrorFor("asignaturas", "cargasHorariasAsignaturas")'
        :errorMessages='alumno.errorsFor("asignaturas", "cargasHorariasAsignaturas")'
        :items='asignaturasPosibles'
        :label='i18n.alumnos.fields.asignaturas.caption'
        :prepend-icon='icons.flask'
        @blur='changed()'
        multiple outlined
      )
        template(v-slot:prepend-item)
          v-list-item(ripple @click='toggleAsignaturas()')
            v-list-item-action
              v-icon {{toggleAsignaturasIcon}}
            v-list-item-content
              v-list-item-title {{i18n.select_all}}
          v-divider.mt-2
      v-textarea(
        v-if='noEsPresente'
        v-model='alumno.observaciones'
        :error='alumno.hasErrorFor("observaciones")'
        :errorMessages='alumno.errorsFor("observaciones")'
        :label='i18n.alumnos.fields.observaciones.caption'
        :prepend-icon='icons.text'
        @change='changed()'
        outlined
      )
</template>

<script>
  import { h, i18n, icons, rules } from '@/../js/util'
  import { AsistenciaAlumno } from '@/../models/asistencia_alumno.model'

  export default {
    props: {
      alumno: { type: AsistenciaAlumno, required: true },
      asignaturasPosibles: { required: true },
      index: Number,
      justificacionesPosibles: { required: true },
      presentismosPosibles: { required: true },
      registraLlegadasTarde: { type: Boolean },
    },
    data: () => {
      return {
        expanded: false,
        dirty: false, // TODO
        i18n,
        icons,
        notificable: false,
        observaciones: '',
        rules
      }
    },
    computed: {
      noEsPresente() {
        let valor = this.alumno.presentismo
        return !h.isNullish(valor) && valor !== 'presente'
      },
      esPresente() {
        let valor = this.alumno.presentismo
        return !h.isNullish(valor) && valor === 'presente'
      },
      muestraLlamadasTarde() {
        return this.registraLlegadasTarde && this.esPresente;
      },
      muestraPresentismo() {
        return !this.alumno.enOtraBurbuja;
      },
      selectedJustificacion() {
        return this.justificacionesPosibles.find(
          obj => obj.value === this.alumno.justificacionInasistenciaId
        )
      },
      requiereOtraJustificacion() {
        return this.noEsPresente &&
          this.selectedJustificacion &&
          this.selectedJustificacion.otra
      },
      requiereAsignaturas() {
        return this.noEsPresente &&
          this.asignaturasPosibles.length > 0
      },
      toggleAsignaturasIcon() {
        if (this.allAsignaturasSelected) return icons.close_box
        if (this.someAsignaturasSelected) return icons.plus_box
        return icons.check_outline
      },
      allAsignaturasSelected() {
        return (this.alumno.asignaturaIds || []).length === this.asignaturasPosibles.length
      },
      someAsignaturasSelected() {
        return (this.alumno.asignaturaIds || []).length > 0 && !this.allAsignaturasSelected
      },
    },
    methods: {
      toggle(nuevo, skip_emit) {
        if (h.isNullish(nuevo)) {
          nuevo = !this.expanded
        }
        this.expanded = nuevo
      },
      toggleAsignaturas(bool) {
        if (h.isNullish(bool)) {
          bool = !this.allAsignaturasSelected
        }

        if (bool) {
          this.alumno.asignaturaIds = this.asignaturasPosibles.slice()
        } else {
          this.alumno.asignaturaIds = []
        }
      },
      toggleNotificableSegunJustificacion() {
        this.alumno.notificable = this.selectedJustificacion &&
          this.selectedJustificacion.notificable
        this.changed()
      },
      changed() {
        this.$emit('change')
      },
      changedBurbuja(value) {
        this.alumno.presentismo = null
        this.alumno.resetPresentismo(null)
        this.changed()
      },
      changedPresentismo(value) {
        this.alumno.resetPresentismo(value)
        this.changed()
      },
      errorSummary() {
        return this.alumno.errors.cursada.message
      },
    },
  }
</script>

<style lang='scss' scoped>
  .alumno-cell {
    --c-color-warning: #eec200;
    --c-color-warning-dark: #8a6116;
    --c-color-warning-darker: #573b00;
    --c-color-warning-light: #ffea8a;
    --c-color-warning-lightest: #fcf1cd;
    --c-color-warning-text: #595130;

    &.has-error {
      background-color: #f2efea;
    }

    .alumno--header {
      display: grid;
      grid-gap: 1em;
      grid-template-columns: 64px auto;

      .alumno--avatar {
        border-radius: 50%;
        background: #ddd;
        display: none;
        width: 64px;
        height: 64px;
      }
      .alumno--info {
        display: grid;
        grid-column: 1 / span 2;
        grid-gap: 0.5em;
        grid-template-columns: auto 4em;

        .alumno--overline {
          grid-column: 1 / span 2;
          margin-bottom: 0.5em;

          .v-chip {
            margin-right: 0.5em;
          }

          .v-chip-danger {
            background-color: #ffc58b;
          }

          .v-chip-warning {
            background-color: #ffea8a;
          }
        }

        .alumno--mas-info {
          position: relative;
          top: -1em;
        }

        .alumno--nombre {
          color: var(--color-dark);
          font-family: 'Roboto Condensed';
          font-size: 1.9rem;
          font-weight: 300;
          letter-spacing: 0.0073529412em;
          line-height: 1.5;
          word-break: break-word;

          .alumno--nombre--apellido {
            font-weight: 600;
          }
        }
      }

      .alumno--detalles {
        grid-column: 1 / span 2;

        .alumno--error {
          background-color: var(--c-color-warning-light);
          color: var(--c-color-warning-dark);
        }

        .alumno--detalle {
          display: flex;

          padding-bottom: 16px;

          .alumno--detalle--icon {
            flex-basis: 64px;

            padding-bottom: 8px;
            padding-top: 8px;

            text-align: center;

            flex-shrink: 0;
          }

          .alumno--detalle--valor {
            flex-grow: 1;

            font-family: "Roboto", sans-serif;
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: 0.025em;
            line-height: 2rem;

            padding-top: 8px;

            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }
  }
</style>
